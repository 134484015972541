// core
import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { scroller } from "react-scroll";
import { useLocation } from '@reach/router';
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const PrivacyPolicy = ({data}) => {
    const location = useLocation()
    const pageContent = data.prismicPrivacyPolicyPage
    const pageData = data.prismicPrivacyPolicyPage.data
    const [activeKey, setActiveKey] = useState(null)
    const [baseURL] = useState(location?.href)
    const isBrowser = typeof window !== "undefined"
    const [policyContent, setPolicyContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const { lang, type, url } = pageContent
    const alternateLanguages = pageContent.alternate_languages || []
    const activeDoc = {
      lang,
      type,
      url,
      alternateLanguages,
    }
    const sectionRef = useRef(null);

    const handlePrivacyCenterClick = (event) => {
      event.preventDefault()
      window.semaphore.push(['showPreferences'])
    }

    useEffect(() => {
      const privacyCenterLinks = document.querySelectorAll('.privacy-center-toggle')
      privacyCenterLinks.forEach(link => {
        link.addEventListener('click', handlePrivacyCenterClick)
      });

      return () => {
        privacyCenterLinks.forEach(link => {
          link.removeEventListener('click', handlePrivacyCenterClick)
        })
      }
    }, [])

    useEffect(() => {
      if (!isBrowser) {
        return
      } else if(isBrowser && baseURL.includes('#')) {
        const splitURL = baseURL.split('#')
        scroller.scrollTo(splitURL[1], {
          duration: 1000,
          delay: 50,
          smooth: true,
        });
      }
    }, [isBrowser, baseURL])

    useEffect(() => {
      import("../utils/accordionPlugin").then(() => {
        $(".accordion").accordionPlugin();
      });
    }, []);
    useEffect(() => {
      if (!isBrowser) return;

      const scriptUrl = `https://gpp.markanthony.com/privacy-policy.js?entity=MAG&language=${activeDoc?.lang == "en-us"?"en":"es-419"}`;
      const mainTag = sectionRef.current;
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.type = 'text/javascript';
      script.defer = true;

      mainTag.appendChild(script);

      script.onerror = () => {
          console.error('Failed to load the privacy policy script.');
          setLoading(false);
      };

      script.onload = () => {
        setLoading(false);
        setPolicyContent(true);
      };

      return () => {
        if (mainTag.contains(script)) {
          mainTag.removeChild(script);
        }
      };
    }, [isBrowser]);
    useEffect(() => {
      if (!isBrowser || !policyContent) return;
      const handleScrollToHash = () => {
        const hash = window.location.hash.substring(1);

        if (hash) {
          const $target = $("#" + hash);
          if ($target.length) {
            const headerHeight = $("header").outerHeight() || 100;
            $("html, body").animate(
              {
                scrollTop: $target.offset().top - headerHeight,
              },
              300
            );
          }
        }
      };
      $(document).ready(function () {
        $(".accordion").accordionPlugin();
        handleScrollToHash();
      });
      const onHashChange = () => {
        handleScrollToHash(); 
      };
      window.addEventListener("hashchange", onHashChange);
      return () => {
        window.removeEventListener("hashchange", onHashChange);
      };
    }, [isBrowser, policyContent]);
    return (
      <Layout activeDocMeta={activeDoc}>
        <Seo 
          title={pageData.meta_title?.text}
          description={pageData.meta_description?.text}
          image={pageData.social_card?.url}
          activeDocMeta={activeDoc} 
        />
        <section id="privacy-policy" className="container inside-content-row" ref={sectionRef}>
          {loading && <div>Loading privacy policy...</div>}
        </section>
      </Layout>
    )
  }

export const query = graphql`
query PrivacyPolicyPageQuery($uid: String, $id: String, $lang: String){
    prismicPrivacyPolicyPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        banner_background {
          alt
          url
        }
        banner_description {
          text
        }
        banner_subtitle {
          text
        }
        banner_title {
          text
        }
        content_header {
          text
        }
        social_card {
          url
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        page_content {
          html
          text
        }
        collapse_item {
          section_header
          section_content {
            html
            text
          }
          item_id
          item_title
          item_content {
            html
            text
          }
        }
      }
    }
  }
`
export default withPrismicPreview(PrivacyPolicy)